import "./App.css";
import "tailwindcss/tailwind.css";
import Navbar from "./components/navbar/Navbar";

function App() {
  return (
    <>
      <div className="h-screen">
        <div className="relative overflow-hidden">
          <div className="relative pt-6">
            <div className="font-mono">
              <Navbar />
              <main className="mt-16 mx-auto max-w-7xl sm:mt-24">
                <div className="text-center">
                  <h1 className="text-1xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-3xl">
                    <span className="block xl:inline">THE CAT CLUB</span>{" "}
                    <span className="block text-cat-900 xl:inline">
                      MEOW...
                    </span>
                  </h1>
                  <p className="mt-3 max-w-md mx-auto text-base text-gray-900 md:mt-5 md:max-w-3xl">
                    All $CAT token holders are part of the cat club. Farm $CAT tokens
                    by providing liquidity to a list of selected ZilSwap pairs
                    or stake your $CAT and gain more $CAT!
                  </p>
                  <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                    <div className="rounded-md shadow">
                      <a
                        href="https://zilswap.io/swap?tokenIn=zil1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq9yf6pz&tokenOut=zil1w7nzlsspelg5t6w72aggwll49s57kcc7pxg2dv"
                        target="blank"
                        className="w-full flex items-center justify-center px-8 py-3 border-2 border-cat-900 text-base font-medium rounded-md text-gray-900 bg-gray-50 hover:bg-cat-500 md:py-4 md:text-lg md:px-10"
                      >
                        BUY ON ZILSWAP
                      </a>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
